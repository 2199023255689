/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../components/layout';
import ProfileBuilder from '../components/registration'
import MetaData from '../components/metadata'

const Join = (props) => {

  return (
    <Layout {...props} plain>
      <MetaData title="Join Us" />
      <ProfileBuilder 
        landing
        favouritePlayer
        favouriteKit
        shirtNumber
        overview
      />
    </Layout>
  )
}

export default Join